<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">{{ $t('transactionType.name') }}</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="transactionType.name" required></v-text-field>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">{{ $t('transactionType.module') }}</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="transactionType.modules"></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">{{ $t('transactionType.module') }}</label>
            </v-col>
            <v-col cols="12">
              <QuillEditor 
              ref="myEditor"
              theme="snow" 
              toolbar="essential" 
              v-model="transactionType.modules"/>
            </v-col>
          </v-row> -->
         
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>

</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      transactionType: {
        id: null,
        name: '',
        modules: ''
      },
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    // Fetch transaction details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/transaction_type/${id}`);
        this.transactionType = response.data;
        // this.$refs.myEditor.setHTML(this.transactionType.modules) 
      } catch (error) {
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const transactionTypeData = {
          name: this.transactionType.name
          // modules: this.$refs.myEditor.getHTML()
          // modules: this.transactionType.modules
        };

        if (this.isAdding) {
          const response = await axios.post('/transaction_type', { transaction_type: transactionTypeData });

          if (response.data) {
            this.$router.push({ name: 'transactionType' });
            this.showAlert({ message: 'Transaction data successfully saved', color: 'success' });
          } else {
            throw new Error(response.message);
          }
        } else {
          const response = await axios.put(`/transaction_type/${this.transactionType.id}`, { transaction_type: transactionTypeData });

          if (response.data) {
            this.$router.push({ name: 'transactionType' });
            this.showAlert({ message: 'User data successfully updated', color: 'success' });
          } else {
            throw new Error('Failed to update transaction type');
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate();
      this.handleSubmit();
      
    },
    handleBlur() {
      // Handle blur event
      console.log('Editor blurred');
    },
    handleChange() {
      console.log(this.$refs.myEditor.getHTML());
      
    },
    getEditorContent() {
      return this.editorContent; // Method to get the current content
    },
    setEditorContent(content) {
      this.editorContent = content; // Method to set the editor content
    }
  }
};
</script>