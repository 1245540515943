<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card>
        <template v-slot:title>
            <div class="text-caption">Awaiting Approval</div>
        </template>

        <v-divider></v-divider>

        <v-card-text>
            <div>
                <v-form ref="form" @submit.prevent="handleSubmit">
                    <v-row>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Distributor Code/Name</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required bgColor="none"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="1">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">PO Distributor</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required bgColor="none"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="1">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Number</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Type</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required bgColor="none"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="1">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">PO Date</label>
                                </v-col>
                                <v-col>
                                    <v-date-input v-model="startDate" variant="outlined" density="compact"
                                        bgColor="none" :multiple="false" prepend-icon="" hide-details
                                        prepend-inner-icon="$calendar"></v-date-input>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="1">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Date</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Distributor Address</label>
                                </v-col>
                                <v-col>
                                    <v-textarea variant="outlined" rows="1" v-model="user.email" required
                                        bgColor="none"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="5">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Period</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Valid From</label>
                                </v-col>
                                <v-col>
                                    <v-date-input v-model="startDate" variant="outlined" density="compact"
                                        bgColor="none" :multiple="false" prepend-icon=""
                                        prepend-inner-icon="$calendar"></v-date-input>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="1">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Valid To</label>
                                </v-col>
                                <v-col>
                                    <v-date-input v-model="startDate" variant="outlined" density="compact"
                                        bgColor="none" :multiple="false" prepend-icon=""
                                        prepend-inner-icon="$calendar"></v-date-input>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <label class="custom-label font-weight-bold">Shipping Information</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Ship to Name</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required bgColor="none"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="1">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Area</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Ship to Address</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required bgColor="none"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" offset="1">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Region</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="user.email" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </v-card-text>

        <template v-slot:append>
            <v-btn @click="submitForm" color="primary">
                {{ isAdding ? $t('button.save') : $t('button.update') }}
            </v-btn>
        </template>
    </v-card>

    <div class="pa-3"></div>
    <v-card>
        <template v-slot:title>

            <v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-fullscreen"
                @click="isBottomSheetOpen = true">
                Full Screen
            </v-btn>

        </template>

        <v-divider></v-divider>

        <v-card-text>
            <v-data-table hide-default-footer fixed-header :headers="headers" :items="items" item-key="name"
                class="frozen-columns-table" :items-per-page="100" height="25vh">

                <!-- Customize Header -->
                <template v-slot:header.w1="{ header }">
                    <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                </template>

                <template v-slot:header.w2="{ header }">
                    <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                </template>

                <template v-slot:header.w3="{ header }">
                    <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                </template>

                <template v-slot:header.w4="{ header }">
                    <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                </template>


                <template v-slot:item.week1="{ item }">
                    <input type="text" v-model="item.week1" class="custom-input" />
                </template>

                <template v-slot:item.week2="{ item }">
                    <input type="text" v-model="item.week2" class="custom-input" />
                </template>

                <!-- Define slot for week3 -->
                <template v-slot:item.week3="{ item }">
                    <input type="text" v-model="item.week3" class="custom-input" />
                </template>

                <!-- Define slot for week4 -->
                <template v-slot:item.week4="{ item }">
                    <input type="text" v-model="item.week4" class="custom-input" />
                </template>

                <!-- Define slot for remarks -->
                <template v-slot:item.remarks="{ item }">
                    <input type="text" v-model="item.remarks" class="custom-input" />
                </template>

                <!-- Append slot for the "Total" row -->
                <template v-slot:body.append>
                    <tr class="elevation-0">
                        <!-- Assuming you want a label for the total row -->
                        <td style="left: 0;">Total</td>
                        <!-- Total for Stock column -->
                        <td></td>
                        <td></td>
                        <td class="text-center">3333</td>
                        <td class="text-center">333</td>
                        <!-- Total for qty_m0 -->
                        <td class="text-center">333</td>
                        <!-- Total for qty_m1 -->
                        <td class="text-center">333</td>
                        <!-- Total for qty_m2 -->
                        <td class="text-center">12341234</td>
                        <td class="text-center">333</td>
                        <!-- Total for qty_m0 -->
                        <td class="text-center">12341234</td>
                        <!-- Total for qty_m1 -->
                        <td class="text-center">12341234</td>
                        <!-- Total for qty_m2 -->
                        <td class="text-center">12341234</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>

        <v-card-action>
            <div class="mx-5">
                <v-row>
                    <v-col cols="12" md>
                        <label class="custom-label font-weight-bold">Vehicle Recommendation</label>
                    </v-col>
                </v-row>
                <v-row align="start" justify="space-around">
                    <v-col cols="12" md="3" v-for="(week, index) in weeks" :key="index">
                        <v-card variant="outlined" class="my-5 mx-0 px-0">
                            <v-card-title class="text-center">
                                <label class="text-caption font-weight-bold">{{ week.week }}</label>
                            </v-card-title>
                            <v-divider></v-divider>
                            <div v-for="(vehicle, vIndex) in week.vehicles" :key="vIndex">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row class="mx-1">
                                                <v-col cols="1" offset="11">
                                                    <v-checkbox v-model="vehicle.selected"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1">
                                                <v-col cols="8">
                                                    <v-select v-model="vehicle.vehicleType" :items="vehicleTypes"
                                                        label="Vehicle Type - Qty"></v-select>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field v-model="vehicle.quantity" label="Qty"
                                                        min="1"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1">
                                                <v-col>
                                                    <label class="custom-label">Dimension/ Capacity :</label>
                                                </v-col>
                                                <v-col>
                                                    <label class="custom-label">{{ vehicle.dimension }}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1">
                                                <v-col>
                                                    <label class="custom-label">Fulfillment CBM :</label>
                                                </v-col>
                                                <v-col>
                                                    <label class="custom-label">{{ vehicle.fulfillment }}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1">
                                                <v-btn variant="text" color="primary"
                                                    @click="viewRecommendation(vehicle)">
                                                    View Qty Recommendation
                                                </v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider class="mt-2"></v-divider>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

        </v-card-action>
    </v-card>

    <v-row class="text-caption mt-2">
        <!-- <v-col>History and Notes</v-col> -->
        <v-col>
            <v-btn-toggle v-model="toggle" color="primary" variant="text">
                <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-history">History</v-btn>
                <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-note">Notes</v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
    <v-row class="mt-0 px-4" v-if="toggle === 0">
        <v-timeline align="start" side="end">
            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>

            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem elit. Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>


            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet, Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>
        </v-timeline>
    </v-row>
    <v-row class="mt-0 px-4" v-if="toggle === 1">
        <v-timeline align="start" side="end">
            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <QuillEditor ref="myEditor" theme="snow" toolbar="essential" v-model="notes" />
                <v-btn class="my-2">Post</v-btn>
            </v-timeline-item>

            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
                            aliquid unde, voluptate a id ea explicabo, aperiam nostrum debitis quaerat fugit. Quaerat
                            praesentium rem autem.
                        </div>
                    </div>
                </div>
            </v-timeline-item>


            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet, Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>
        </v-timeline>
    </v-row>


    <!-- Bottom Sheet for Full Screen Card -->
    <v-bottom-sheet v-model="isBottomSheetOpen" fullscreen>
        <v-card>
            <template v-slot:title>

                <v-btn class="mx-1" color="error" variant="outlined" prepend-icon="mdi-fullscreen"
                    @click="isBottomSheetOpen = false">
                    Exit Full Screen
                </v-btn>

            </template>

            <v-divider></v-divider>

            <v-card-text>
                <v-data-table hide-default-footer fixed-header :headers="headers" :items="items" item-key="name"
                    class="frozen-columns-table" :items-per-page="100" height="50vh">

                    <!-- Customize Header -->
                    <template v-slot:header.w1="{ header }">
                        <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                            :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                    </template>

                    <template v-slot:header.w2="{ header }">
                        <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                            :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                    </template>

                    <template v-slot:header.w3="{ header }">
                        <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                            :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                    </template>

                    <template v-slot:header.w4="{ header }">
                        <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                            :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                    </template>


                    <template v-slot:item.week1="{ item }">
                        <input type="text" v-model="item.week1" class="custom-input" />
                    </template>

                    <template v-slot:item.week2="{ item }">
                        <input type="text" v-model="item.week2" class="custom-input" />
                    </template>

                    <!-- Define slot for week3 -->
                    <template v-slot:item.week3="{ item }">
                        <input type="text" v-model="item.week3" class="custom-input" />
                    </template>

                    <!-- Define slot for week4 -->
                    <template v-slot:item.week4="{ item }">
                        <input type="text" v-model="item.week4" class="custom-input" />
                    </template>

                    <!-- Define slot for remarks -->
                    <template v-slot:item.remarks="{ item }">
                        <input type="text" v-model="item.remarks" class="custom-input" />
                    </template>

                    <!-- Append slot for the "Total" row -->
                    <template v-slot:body.append>
                        <tr class="elevation-0">
                            <!-- Assuming you want a label for the total row -->
                            <td style="left: 0;">Total</td>
                            <!-- Total for Stock column -->
                            <td></td>
                            <td></td>
                            <td class="text-center">3333</td>
                            <td class="text-center">333</td>
                            <!-- Total for qty_m0 -->
                            <td class="text-center">333</td>
                            <!-- Total for qty_m1 -->
                            <td class="text-center">333</td>
                            <!-- Total for qty_m2 -->
                            <td class="text-center">12341234</td>
                            <td class="text-center">333</td>
                            <!-- Total for qty_m0 -->
                            <td class="text-center">12341234</td>
                            <!-- Total for qty_m1 -->
                            <td class="text-center">12341234</td>
                            <!-- Total for qty_m2 -->
                            <td class="text-center">12341234</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-action>
                <div class="mx-5">
                    <v-row>
                        <v-col cols="12" md>
                            <label class="custom-label font-weight-bold">Vehicle Recommendation</label>
                        </v-col>
                    </v-row>
                    <v-row align="start" justify="space-around">
                        <v-col cols="12" md="3" v-for="(week, index) in weeks" :key="index">
                            <v-card variant="outlined" class="my-5 mx-0 px-0">
                                <v-card-title class="text-center">
                                    <label class="text-caption font-weight-bold">{{ week.week }}</label>
                                </v-card-title>
                                <v-divider></v-divider>
                                <div v-for="(vehicle, vIndex) in week.vehicles" :key="vIndex">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-row class="mx-2">
                                                    <v-col cols="1" offset="11">
                                                        <v-checkbox v-model="vehicle.selected"></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="mx-1">
                                                    <v-col cols="8">
                                                        <v-select v-model="vehicle.vehicleType" :items="vehicleTypes"
                                                            label="Vehicle Type - Qty"></v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-text-field v-model="vehicle.quantity" label="Qty"
                                                            min="1"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="mx-1">
                                                    <v-col>
                                                        <label class="custom-label">Dimension/ Capacity :</label>
                                                    </v-col>
                                                    <v-col>
                                                        <label class="custom-label">{{ vehicle.dimension }}</label>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="mx-1">
                                                    <v-col>
                                                        <label class="custom-label">Fulfillment CBM :</label>
                                                    </v-col>
                                                    <v-col>
                                                        <label class="custom-label">{{ vehicle.fulfillment }}</label>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="mx-1">
                                                    <v-btn variant="text" color="primary"
                                                        @click="viewRecommendation(vehicle)">
                                                        View Qty Recommendation
                                                    </v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider class="mt-2"></v-divider>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

            </v-card-action>
        </v-card>
    </v-bottom-sheet>


</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
    components: {
        Breadcrumbs
    },
    data() {
        return {
            user: {
                id: null,
                email: '',
                password: ''
            },
            roles: [],
            selectedRoleId: null,
            tab: null,
            length: 3,
            headers: [
                {
                    title: 'REQ. DELIVERY DATE',
                    align: 'center',
                    children: [
                        { title: 'SKU', value: 'sku', align: 'center', clickable: true },
                        { title: 'Product Short Name', value: 'shortName', align: 'center' },
                        { title: 'Product Name', value: 'productName', align: 'center' },
                        { title: 'UOM', value: 'uom', align: 'center' },
                        { title: 'ROFO Total', value: 'rofoTotal', align: 'center' },
                        { title: 'ROFO Outstanding', value: 'rofoOutstanding', align: 'center' },
                        { title: 'Grand Total', value: 'grandTotal', align: 'center' },
                    ],
                },
                {
                    title: '',
                    align: 'center',
                    value: 'w1',
                    children: [
                        { title: 'Week 1', value: 'week1', align: 'center' },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    value: 'w2',
                    children: [
                        { title: 'Week 2', value: 'week2', align: 'center' },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    value: 'w3',
                    children: [
                        { title: 'Week 3', value: 'week3', align: 'center' },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    value: 'w4',
                    children: [
                        { title: 'Week 4', value: 'week4', align: 'center' },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    children: [
                        { title: 'Remarks', value: 'remarks', align: 'center' },
                    ]
                },

            ],
            isBottomSheetOpen: false,
            toggle: null,
            notes: null,
            startDate: null,
            items: [
                {
                    sku: 'FN134',
                    shortName: 'Selera Asal',
                    productName: 'ABC Soup Ayam Bawang',
                    uom: 5,
                    rofoTotal: 15,
                    rofoOutstanding: 999,
                    grandTotal: 999,
                    week1: 1111,
                    week2: 2222,
                    week3: 3333,
                    week4: 4444,
                    remarks: 'This is a remark'

                }
            ],
            vehicleTypes: [
                'Tronton Reefer',
                'CDD Long Box 4x2',
                'CDD Box 24"',
                // Add more vehicle types as needed
            ],
            weeks: [
                {
                    week: 'Week 1',
                    vehicles: [
                        {
                            vehicleType: 'Tronton Reefer',
                            quantity: 1,
                            dimension: '30 CBM/10,000 Kg',
                            fulfillment: '83%',
                            selected: false,
                        },
                        {
                            vehicleType: 'CDD Box 24"',
                            quantity: 1,
                            dimension: '24 CBM/5,000 Kg',
                            fulfillment: '104%',
                            selected: false,
                        },
                    ],
                },
                {
                    week: 'Week 2',
                    vehicles: [
                        {
                            vehicleType: 'CDD Long Box 4x2',
                            quantity: 3,
                            dimension: '43 CBM/12,000 Kg',
                            fulfillment: '98%',
                            selected: false,
                        },
                    ],
                },
                {
                    week: 'Week 3',
                    vehicles: [
                        {
                            vehicleType: 'CDD Box 24"',
                            quantity: 2,
                            dimension: '24 CBM/14,000 Kg',
                            fulfillment: '100%',
                            selected: false,
                        },
                    ],
                },
                {
                    week: 'Week 4',
                    vehicles: [
                        {
                            vehicleType: 'Tronton Reefer',
                            quantity: 1,
                            dimension: '30 CBM/10,000 Kg',
                            fulfillment: '95%',
                            selected: false,
                        },
                        {
                            vehicleType: 'CDD Box 24"',
                            quantity: 1,
                            dimension: '24 CBM/5,000 Kg',
                            fulfillment: '101%',
                            selected: false,
                        },
                    ],
                },
            ],
        };
    },
    watch: {
        length(val) {
            this.tab = val - 1
        }
    },
    created() {

    },
    computed: {
        isAdding() {
            return this.$route.params.action === 'add';
        },
        breadcrumbItems() {
            const action = this.$route.params.action;
            const route = this.$route.name;
            const items = breadcrumbConfig[route];

            if (typeof items === 'function') {
                return items(action);
            }

            return items || [];
        }
    },
    async mounted() {
        const { action, id } = this.$route.params;

        // Fetch roles
        try {
            const response = await axios.get('/role'); // Endpoint for fetching roles
            this.roles = response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.messages
                ? error.response.data.messages
                : error.response?.message || 'An error occurred';

            this.showAlert({ message: errorMessage, color: 'error' });
        }

        // Fetch user details if editing
        if (action === 'edit' && id) {
            try {
                const response = await axios.get(`/users/${id}`);
                this.user = response.data;
                this.selectedRoleId = this.user.role_id; // Set selectedRoleId based on user data
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async handleSubmit() {
            try {
                const userData = {
                    email: this.user.email,
                    password: this.user.password,
                    password_confirmation: this.user.password,
                    role_id: this.selectedRoleId // Include role_id in submit data
                };

                if (this.isAdding) {
                    const response = await axios.post('/signup', { user: userData });
                    this.showAlert({ message: 'User data successfully saved', color: 'success' });
                    if (response.data) {
                        this.$router.push({ name: 'user' });
                    } else {
                        throw new Error(response.message);
                    }
                } else {
                    const response = await axios.put(`/users/${this.user.id}`, { user: userData });
                    this.showAlert({ message: 'User data successfully update', color: 'success' });
                    if (response.data) {
                        this.$router.push({ name: 'user' });
                    } else {
                        throw new Error('Failed to update user');
                    }
                }
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        submitForm() {
            this.$refs.form.validate(); // Trigger form validation
            this.handleSubmit(); // Call the submit method
        },
        toggleFullScreen() {
            this.isBottomSheetOpen = true;
        },
        viewRecommendation(vehicle) {
            // Handle viewing quantity recommendation logic here
            console.log('Viewing recommendation for:', vehicle);
        },
        getSelectedVehicles() {
            const selectedVehicles = [];
            this.weeks.forEach(week => {
                week.vehicles.forEach(vehicle => {
                    if (vehicle.selected) {
                        selectedVehicles.push(vehicle);
                    }
                });
            });
            return selectedVehicles;
        },
    }
};
</script>

<style scoped>
.v-col {
    padding: 5px;
}

.custom-input {
    border: 1px solid #ddd;
    padding: 6px 6px;
    border-radius: 2px;
    width: 11vw;
    box-sizing: border-box;
}

.custom-input:focus {
    border-color: #1976d2;
    outline: none;
}

/* Apply the same fixed positioning to the Total row's fixed columns */
.fixed-column {
    position: sticky;
    background-color: white;
    z-index: 1;
}

/* Apply specific width for each fixed column to match headers */
.total-label {
    width: 100px;
    /* Adjust this to match the width of the first column */
}

.fixed-column:nth-child(2) {
    left: 100px;
    /* Adjust based on the width of the first fixed column */
}

/* Ensure the sticky columns stay in place when scrolling */
tr>.fixed-column {
    box-shadow: 2px 0px 5px -2px rgba(0, 0, 0, 0.2);
}

::v-deep .v-table.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th {
    padding-bottom: 14px;
    color: black;
}
</style>