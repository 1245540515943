<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newSalesTarget') }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="salesTarget" :actions="tableActions" :loading="loading" />
      </v-col>
    </v-row>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
          { title: 'Total Qty', key: 'total_qty', align: 'start', clickable: true },
          { title: 'Month', key: 'month', align: 'start' },
          { title: 'Year', key: 'year', align: 'start' },
          { title: '', key: 'actions', align: 'end', sortable: false },
        ],
        salesTarget: [],
        tableActions: {
          edit: this.navigateToForm,
          delete: this.deleteProductDivision,
          view: this.navigateToForm
        },
        loading: false
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'salesTarget-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchSalesTarget() {
        this.loading = true;
        try {
          const response = await axios.get('/sales_target');
          this.salesTarget = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, salesTarget = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'salesTarget-view', params: { action, id: salesTarget.id } });
        } else {
          this.$router.push({ name: 'salesTarget-form', params: { action, id: salesTarget.id } });
        }
      },
      async deleteProductDivision(salesTargetToDelete) {
        try {
          const response = await axios.delete(`/sales_target/${salesTargetToDelete.id}`);
  
          if (response.data) {
            this.salesTarget = this.salesTarget.filter(u => u.id !== salesTargetToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete salesTarget');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    mounted() {
      this.fetchSalesTarget();
    }
  };
  </script>