<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
        <v-col cols="12" class="d-flex justify-end">
            <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newCmo') }}</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-layout class="overflow-visible" style="height: 40px;">
                <v-bottom-navigation v-model="value" active>
                    <v-btn @click="fetchUsers('All')">All</v-btn>
                    <v-btn @click="fetchUsers('Area')">Awaiting Approval</v-btn>
                    <v-btn @click="fetchUsers('Region')">Approved</v-btn>
                    <v-btn @click="fetchUsers('Domestic')">Revised</v-btn>
                    <v-btn @click="fetchUsers('Domestic')">Canceled</v-btn>
                </v-bottom-navigation>
            </v-layout>
        </v-col>
    </v-row>
    <v-row class="d-flex flex-row-reverse">
        <v-col cols="4">
            <v-text-field placeholder="Search" density="compact" v-model="filterParams"
                append-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-data-table :headers="headers" :items="rofo" :loading="loading">
                <template v-slot:loading>
                    <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            value: 0,
            loading: false,
            headers: [
                { title: 'Transaction Number', key: 'email', align: 'start', clickable: true },
                { title: 'Period', key: 'email', align: 'start' },
                { title: 'Transaction Date', key: 'email', align: 'start' },
                { title: 'Distributor', key: 'email', align: 'start' },
                { title: 'Created by', key: 'email', align: 'start' },
                { title: 'Status', key: 'status', align: 'start' },
            ],
            rofo: [],
            tableActions: {
                edit: this.navigateToForm,
                delete: this.deleteUser,
                view: this.navigateToForm
            }
        };
    },
    computed: {
        breadcrumbItems() {
            const route = this.$route.name;
            const action = this.$route.params.action;
            const breadcrumbItems = breadcrumbConfig[route];

            // Handle case where the route is dynamic, e.g., 'user-form'
            if (typeof breadcrumbItems === 'function') {
                return breadcrumbItems(action);
            }

            return breadcrumbItems || [];
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchUsers() {
            this.loading = true;
            try {
                const response = await axios.get('/users');
                this.rofo = response.data;
                setTimeout(() => this.loading = false, 1000);
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
                this.loading = false;
            }
        },
        navigateToForm(action, user = { id: null }) {
            if (action == 'view') {
                this.$router.push({ name: 'cmo-view', params: { action, id: user.id } });
            } else {
                this.$router.push({ name: 'cmo-form', params: { action, id: user.id } });
            }
        },
        async deleteUser(userToDelete) {
            try {
                const response = await axios.delete(`/rofo/${userToDelete.id}`);

                if (response.data) {
                    this.rofo = this.rofo.filter(u => u.id !== userToDelete.id);

                    this.showAlert({ message: 'Success', color: 'success' });
                } else {
                    throw new Error('Failed to delete user');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        }
    },
    mounted() {
        this.fetchUsers();
    }
};
</script>

<style scoped>
::v-deep .v-bottom-navigation__content {
    justify-content: left !important;
}
</style>