<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card elevation="0" class="mt-2">
        <template v-slot:append>
            <div class="d-flex flex-row-reverse">
                <v-btn class="text-none ma-1" color="primary">
                    Approve
                </v-btn>
                <v-btn class="text-none ma-1" color="error">
                    Cancel
                </v-btn>
                <v-btn class="text-none ma-1" color="warning">
                    Submit
                </v-btn>
            </div>
        </template>

        <v-divider></v-divider>

        <v-card-text>
            <!-- Stepper Row -->
            <v-row>
                <v-col>
                    <v-stepper v-model="e1">
                        <template v-slot:default="{ prev, next }">
                            <v-stepper-header>
                            <template v-for="(history,index) in approvalsHistory" :key="`${index + 1}-step`">
                                <v-stepper-item
                                :complete="history.status === 'Complete'"
                                :step="`Step {{ history.step }}`"
                                :value="index + 1"
                                
                                ></v-stepper-item>

                                <v-divider v-if="index < approvalsHistory.length - 1"></v-divider>
                            </template>
                            </v-stepper-header>

                            <v-stepper-window>
                            <v-stepper-window-item
                                v-for="history in approvalsHistory"
                                :key="`${history.step}-content`"
                                :value="history.step"
                            >
                                <v-card>
                            
                                <v-row>
                                        <v-col cols="6">
                                            <v-card class="mx-auto">
                                                <template v-slot:title>
                                                    <div class="bg-surface-light">
                                                        <span>Approval Detail</span>
                                                    </div>

                                                </template>

                                                <v-card-text class=" pt-4">
                                                    <v-row>
                                                        <v-col cols="3">Type</v-col>
                                                        <v-col cols="9">{{ approvalsData.type }}</v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">Number</v-col>
                                                        <v-col cols="9">{{ approvalsData.document.transaction_number }}</v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">Status</v-col>
                                                        <v-col cols="9">{{ approvalsData.status }}</v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">&nbsp;</v-col>
                                                        <v-col cols="9"></v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-card class="mx-auto">
                                                <template v-slot:title>
                                                    <div class="bg-surface-light">
                                                        <span>Approval Status</span>
                                                    </div>

                                                </template>

                                                <v-card-text class=" pt-4">
                                                    <v-row>
                                                        <v-col cols="3">Step</v-col>
                                                        <v-col cols="9">{{  history.step }}</v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">Status</v-col>
                                                        <v-col cols="9">{{ history.status }}</v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">Current Approver</v-col>
                                                        <v-col cols="9">{{  history.user.full_name }}</v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">Previous Approver</v-col>
                                                        <v-col cols="9"></v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-stepper-window-item>
                            </v-stepper-window>

                            <v-stepper-actions
                                :disabled="disabled"
                                @click:next="next"
                                @click:prev="prev"
                            ></v-stepper-actions>
                        </template>
                    </v-stepper>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            loading: false,
            approvalNeeded: {
                id: null,
                type: '',
                document: null,
                status: '',
            },
            headers: [
                { title: 'Name', key: 'name', align: 'start', clickable: true },
                { title: 'Status', key: 'status', align: 'start' },
                { title: '', key: 'actions', align: 'end', sortable: false },
            ],
            approvalsData: [],
            approvalsHistory: [],
            e1: 1,
        };
    },
    computed: {
        breadcrumbItems() {
            const action = this.$route.params.action;
            const route = this.$route.name;
            const items = breadcrumbConfig[route];

            if (typeof items === 'function') {
                return items(action);
            }

            return items || [];
        },
        disabled () {
            return this.e1 === 1 ? 'prev' : this.e1 === this.steps ? 'next' : undefined
        },
    },
    async mounted() {
        const { id } = this.$route.params;
        // Fetch approval audit trail here
        if (id) {
            this.fetchApprovalNeeded(id);
            this.fetchApprovalHistory(id);
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchApprovalNeeded(id) {
            try {
                const response = await axios.get(`/approval_step/${id}`);
                this.approvalsData= response.data;
            } catch (error) {
                     const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error asdfasdf';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async fetchApprovalHistory(id) {
            try {
                const response = await axios.get(`/approval_history/${id}`);
                this.approvalsHistory = response.data;
            } catch (error) {
                     const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error asdfasdf';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        navigateToDocument(doc) {
            const act = 'edit';
            this.$router.push({ name: 'rofo-view', params: { act, id: doc.object_id } });
        }
    },
};
</script>

<style scoped>
.custom-label {
    font-weight: bold;
    font-size: 11px;
}
</style>