<template>
    <div>
        <v-data-table :headers="headers" :items="items" item-key="id" class="elevation-1" :loading="loading" :mobile="mobile"  
          :v-model="selected" :show-select='showSelect'> 
            <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ item }">
                <!-- Custom View for Status Column -->
                <template v-if="header.key === 'status'">
                    <v-chip :color="getStatusColor(item.status)" variant="flat" size="x-small">
                        {{ item.status }}
                    </v-chip>
                </template>
                <template v-else-if="header.key === 'actions'">
                    <v-btn v-if="actions.edit" density="compact" variant="text" color="primary"
                        @click="() => handleAction('edit', item)">{{ $t('actions.edit') }}</v-btn>
                    <v-btn v-if="actions.delete" density="compact" variant="text" color="error"
                        @click="confirmDelete(item)">{{ $t('actions.delete') }}</v-btn>
                </template>
                
                <template v-else-if="header.clickable">
                    <v-btn variant='text' @click="handleAction('view', item)" class="pa-0" density="compact">
                        {{ item[header.key] }}
                    </v-btn>
                </template>
                <template v-else>
                    {{ item[header.key] }}
                </template>
            </template>
            <template v-slot:loading>
                    <v-skeleton-loader type="table-row@5"></v-skeleton-loader>
            </template>
        </v-data-table>

        <!-- Confirmation Dialog -->
        <v-dialog v-model="showConfirmation" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    Confirm Deletion
                </v-card-title>
                <v-card-subtitle>
                    Are you sure you want to delete this item?
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="showConfirmation = false">
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteItem">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        actions: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Array,
            default: []
        },
        showSelect: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showConfirmation: false,
            itemToDelete: null
        };
    },
    methods: {
        handleAction(actionName, item) {
            if (this.actions[actionName]) {
                if (actionName == 'edit') {
                    this.actions[actionName]('edit', item)
                } else if (actionName == 'view') {
                    this.actions[actionName]('view', item)
                } else {
                    this.actions[actionName](item);
                }
            }
        },
        confirmDelete(item) {
            this.itemToDelete = item;
            this.showConfirmation = true;
        },
        deleteItem() {
            if (this.actions.delete && this.itemToDelete) {
                this.actions.delete(this.itemToDelete);
                this.itemToDelete = null;
                this.showConfirmation = false;
            }
        },
        navigateToView(item) {
            this.$router.push({ name: 'home', params: { id: item.id } });
        },
        getStatusColor(status) {
            switch (status.toLowerCase()) {
                case 'active':
                    return 'green';
                case 'inactive':
                    return 'red';
                case 'draft':
                    return 'yellow';
                case 'pending':
                    return 'orange';
                case 'request for revise':
                    return 'orange';
                case 'submitted':
                    return 'primary';
                case 'approved':
                    return 'green';
                default:
                    return 'primary';
            }
        }
    }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>