<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
        <v-col cols="12" class="d-flex justify-end">
            <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newRollingForecast') }}</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-layout class="overflow-visible" style="height: 40px;">
                <v-bottom-navigation v-model="type" active>
                    <v-btn @click="fetchRofo('District')">District</v-btn>
                    <v-btn @click="fetchRofo('Area')">Area</v-btn>
                    <v-btn @click="fetchRofo('Region')">Region</v-btn>
                    <v-btn @click="fetchRofo('Domestic')">Domestic</v-btn>
                </v-bottom-navigation>
            </v-layout>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <!-- <v-data-table :headers="headers" :items="rofo" :loading="loading">
                <template v-slot:loading>
                    <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                </template>
</v-data-table> -->
            <data-table :headers="headers" :items="rofo" :actions="tableActions" />
        </v-col>
    </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            type: 0,
            loading: false,
            headers: [
                { title: 'Transaction Number', key: 'transaction_number', align: 'start', clickable: true },
                { title: 'Period', value: 'period.name', align: 'start' },
                { title: 'Transaction Date', key: 'transaction_date', align: 'start' },
                { title: 'District', key: 'district', align: 'start' },
                { title: 'Region', key: 'region', align: 'start' },
                { title: 'Area', key: 'area', align: 'start' },
                { title: 'Status', key: 'status', align: 'start' },
            ],
            rofo: [],
            tableActions: {
                // edit: this.navigateToForm,
                // delete: this.deleteRofo,
                view: this.navigateToForm
            }
        };
    },
    computed: {
        breadcrumbItems() {
            const route = this.$route.name;
            const action = this.$route.params.action;
            const breadcrumbItems = breadcrumbConfig[route];

            // Handle case where the route is dynamic, e.g., 'user-form'
            if (typeof breadcrumbItems === 'function') {
                return breadcrumbItems(action);
            }

            return breadcrumbItems || [];
        }
    },
    watch: {
        type() {
            this.fetchRofo();
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchRofo() {
            this.loading = true;
            let coverageTypeParam;
            switch (this.type) {
                case 0:
                    coverageTypeParam = 'District';
                    break;
                case 1:
                    coverageTypeParam = 'Area';
                    break;
                case 2:
                    coverageTypeParam = 'Region';
                    break;
                case 3:
                    coverageTypeParam = 'Domestic';
                    break;
            }

            try {
                const response = await axios.get(`/rolling_forecast?type=${coverageTypeParam}`);
                this.rofo = response.data;
                setTimeout(() => this.loading = false, 200);
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
                this.loading = false;
            }
        },
        navigateToForm(action, rofo = { id: null }) {
            let coverageTypeParam;

            switch (this.type) {
                case 0:
                    coverageTypeParam = 'District';
                    break;
                case 1:
                    coverageTypeParam = 'Area';
                    break;
                case 2:
                    coverageTypeParam = 'Region';
                    break;
                case 3:
                    coverageTypeParam = 'Domestic';
                    break;
            }

            if (action === 'view') {
                this.$router.push({ name: 'rofo-view', params: { action, id: rofo.id, type: coverageTypeParam } });
            } else {
                this.$router.push({ name: 'rofo-form', params: { action, id: rofo.id, type: coverageTypeParam } });
            }
        },
        async deleteRofo(rofoToDelete) {
            try {
                const response = await axios.delete(`/rofo/${rofoToDelete.id}`);

                if (response.data) {
                    this.rofo = this.rofo.filter(u => u.id !== rofoToDelete.id);

                    this.showAlert({ message: 'Success', color: 'success' });
                } else {
                    throw new Error('Failed to delete rofo');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        }
    },
    mounted() {
        this.fetchRofo();
    }
};
</script>

<style scoped>
::v-deep .v-bottom-navigation__content {
    justify-content: left !important;
}
</style>