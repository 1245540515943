<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card elevation="0" class="mt-2">
        <template v-slot:append>
            <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', approvals)">
                Customize
            </v-btn>
        </template>

        <v-divider></v-divider>
        <v-card-text>
            <!-- Module -->
            <v-row>
                <v-col lg="3" md="3" sm="12" cols="12">
                    <label class="custom-label">Module</label>
                </v-col>
                <v-col cols="12" md="6">
                    <div>{{ moduleType }}</div>
                </v-col>
            </v-row>
            <!-- Transaction Type -->
            <v-row>
                <v-col lg="3" md="3" sm="12" cols="12">
                    <label class="custom-label">Transaction Type</label>
                </v-col>
                <v-col cols="12" md="6">
                    <div>{{ transactionTypeName }}</div>
                </v-col>
            </v-row>
            <!-- Active on -->
            <v-row>
                <v-col lg="3" md="3" sm="12" cols="12">
                    <label class="custom-label">Active on</label>
                </v-col>
                <v-col cols="6" md="3">
                    <div>{{ formattedStartDate + ' - ' + formattedEndDate }}</div>
                </v-col>
            </v-row>
            <!-- Status -->
            <v-row>
                <v-col lg="3" md="3" sm="12" cols="12">
                    <label class="custom-label">Status</label>
                </v-col>
                <v-col cols="12" md="6">
                    <div>{{ status }}</div>
                </v-col>
            </v-row>
            <!-- Coverage Type -->
            <v-row>
                <v-col lg="3" md="3" sm="12" cols="12">
                    <label class="custom-label">Coverage Type</label>
                </v-col>
                <v-col cols="12" md="6">
                    <div>{{ coverageTypeName }}</div>
                </v-col>
            </v-row>
            <!-- Approver Type -->
            <v-row>
                <v-col lg="3" md="3" sm="12" cols="12">
                    <label class="custom-label">Approver Type</label>
                </v-col>
                <v-col cols="12" md="6">
                    <div>{{ approverType }}</div>
                </v-col>
            </v-row>
            <!-- Approval Level & Role -->
            <v-divider class="my-6"></v-divider>
            <v-row>
                <v-col lg="3" md="3" sm="12" cols="12">
                    <label class="custom-label">Approval Level & Role</label>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="formattedApprovers" hide-default-footer>
                        <template v-slot:item.approverName="{ item }">
                            <div>{{ item.approverName }}</div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { mapActions } from 'vuex';

export default {
    components: {
        Breadcrumbs,
    },
    async mounted() {
        const { id } = this.$route.params;
        if (id) {
            try {
                const response = await axios.get(`/approval/${id}`);
                const approval = response.data;
                this.approvals = response.data;

                this.moduleType = approval.module;
                this.transactionType = approval.transaction_type_id;
                this.status = approval.status;
                this.coverageType = approval.coverage_type_id;
                this.startDate = new Date(approval.start_date);
                this.endDate = new Date(approval.end_date);
                this.approverType = approval.approver_type;

                await this.initializeApprover(approval.approvers);

                this.fetchAdditionalData();

            } catch (error) {
                console.error('Error fetching approval details', error);
            }
        }
    },
    data() {
        return {
            moduleType: '',
            transactionType: null,
            coverageType: null,
            approverType: null,
            startDate: null,
            endDate: null,
            status: '',
            transactionTypeName: '',
            coverageTypeName: '',
            approvers: [],
            approvals: [],
            roles: [],
            users: [],
            headers: [
                { title: 'Transaction Type', value: 'transaction_type.name' },
                { title: 'Module/Type', key: 'module' },
                { title: 'Approval Level', key: 'level' },
                { title: 'Approver Name/Email', key: 'approverName' },
            ],
        };
    },
    computed: {
        breadcrumbItems() {
            const action = this.$route.params.action;
            const route = this.$route.name;
            const items = breadcrumbConfig[route];

            if (typeof items === 'function') {
                return items(action);
            }

            return items || [];
        },
        formattedStartDate() {
            return this.startDate ? this.startDate.toLocaleDateString() : '';
        },
        formattedEndDate() {
            return this.endDate ? this.endDate.toLocaleDateString() : '';
        },
        formattedApprovers() {
            return this.approvers.map(approver => {
                const approverName = this.approverType === 'By Role'
                    ? this.roles.find(role => role.id === approver.approver)?.name
                    : this.users.find(user => user.id === approver.approver)?.email;

                return {
                    ...approver,
                    approverName: approverName || 'Unknown'
                };
            });
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchAdditionalData() {
            try {
                const [transactionResponse, coverageResponse] = await Promise.all([
                    axios.get(`/transaction_type/${this.transactionType}`),
                    axios.get(`/coverage_type/${this.coverageType}`),
                ]);

                this.transactionTypeName = transactionResponse.data.name;
                this.coverageTypeName = coverageResponse.data.name;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async initializeApprover(approvers) {
            this.approvers = approvers;
        },
        goToCustomizePage(action, approval) {
            this.$router.push({ name: 'approval-form', params: { action, id: approval.id } });
        },
        async fetchRoles() {
            try {
                const response = await axios.get('/role');
                this.roles = response.data;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async fetchUsers() {
            this.loading = true;
            try {
                const response = await axios.get('/users');
                this.users = response.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                const errorMessage = error.response?.data?.messages 
                                    ? error.response.data.messages 
                                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
    },
    created() {
        this.fetchRoles();
        this.fetchUsers();
    }
};
</script>

<style scoped>
.custom-label {
    font-weight: bold;
    font-size: 11px;
}
</style>
