import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,   
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the Bearer token in the Authorization header
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  },
  error => Promise.reject(error)
);

// Add a response interceptor to handle expired signatures
instance.interceptors.response.use(
  response => response,
  error => {
    if(error.response.data.error === 'Signature has expired') {
        localStorage.removeItem('authToken');
        router.push({ name: 'Login' });
    } else if (error.response && error.response.headers['content-type'].includes('text/html')) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(error.response.data, 'text/html');
      const errorMessage = doc.querySelector('body').innerText || '';
      // console.log(error);
      if (errorMessage.includes('Signature has expired')) {
        // Clear the token and redirect to login page
        localStorage.removeItem('authToken');
        router.push({ name: 'Login' });
      }
    } else {
      console.log('Error response does not contain text/html or no response object available.');
    }
    
    return Promise.reject(error);
  }
);

export default instance;
