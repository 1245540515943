<template>
  <v-container fluid fill-height class="d-flex align-center justify-center">
    <Alert></Alert>
    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" class="d-flex justify-center mb-4">
        <v-img max-width="150" :src="require('@/assets/abc.png')"></v-img>
      </v-col>
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-3 px-6 py-10 rounded-lg">
          <v-card-title class="mt-2">
            <h3 class="text-center font-weight-medium mb-0">Welcome Back</h3>
            <p class="text-center text-subtitle-1">Login to manage your dashboard</p>
          </v-card-title>
          <v-form @submit.prevent="login">
            <v-row>
              <v-col cols="12" class="mx-1 mt-3">
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  placeholder="Enter email"
                  prepend-inner-icon="mdi-email-outline"
                  outlined
                  rounded
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mx-1 mt-1">
                <v-text-field
                  v-model="password"
                  :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible ? 'text' : 'password'"
                  prepend-inner-icon="mdi-lock-outline"
                  placeholder="Enter your password"
                  label="Password"
                  outlined
                  rounded
                  @click:append-inner="visible = !visible"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-right">
                <!-- <v-btn text color="primary" class="mb-4">Forgot password?</v-btn> -->
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn color="primary" block size="large" class="py-3" type="submit" :loading="loading" rounded>
                  Login
                  <template v-slot:loader>
                    <v-progress-circular indeterminate></v-progress-circular>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="text-center mt-4">
            <!-- <v-btn text class="font-weight-bold" @click="goToRegister">Don't have an account? Register</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-snackbar v-model="snackbar" variant="outlined" color="error">
    {{ snackMessage }}
    <template v-slot:actions>
      <v-btn color="primary" variant="text" @click="snackbar = false">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import store from '../store' // Import the store
import axios from '../plugins/axios';

const email = ref('')
const password = ref('')
const router = useRouter()
const visible = ref(false)
const loading = ref(false)
const snackbar = ref(false)
const snackMessage = ref('')

const login = async () => {
  try {
    loading.value = true;
    localStorage.removeItem('authToken');
    localStorage.removeItem('userData');
    const response = await axios.post('/login', {
      user: {
        email: email.value,
        password: password.value,
      },
    });

    const token = response.headers['authorization'];
    if (token) {
      const bearerToken = token.startsWith('Bearer ') ? token.substring(7) : token;
      const userData = response.data.status.data.user;
      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.setItem('authToken', bearerToken);

      store.dispatch('login', response.data.status.data.user);
      loading.value = false;
      router.push('/home');
    } else {
      alert('Login failed: No token received');
    }
  } catch (error) {
    loading.value = false;
    snackbar.value = true;
    const errorMessage = error.response?.data?.messages
      ? error.response.data.messages
      : error.response?.message || 'Invalid Credentials';
    snackMessage.value = errorMessage
  }
};
</script>

<style scoped>
.v-container {
  height: 100vh;
  background-color: #f9fafb;
}

.v-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.text-subtitle-1 {
  font-size: 14px;
  color: #777;
}

.v-btn {
  border-radius: 8px;
}

.v-btn:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
</style>
