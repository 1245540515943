<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">First Name</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="user.first_name" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Last Name</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="user.last_name" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Email</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="user.email" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Password</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="user.password" type="password" :required="isAdding" bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <!-- Role Selection -->
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">User Role</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="selectedRoleId" :items="roles" item-value="id" item-title="name"
                placeholder="Select Role" required></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Area Access</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="selectedCoverageTypeId" :items="coverageTypes" item-value="item" item-title="name"
                placeholder="Select" required @update:modelValue="findCoverageByType($event)"></v-select>
            </v-col>
          </v-row>
          <v-row class="pb-5">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label"></label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select v-model="user.coverages" chips multiple :items="coverages" item-value="id" item-title="name"
                placeholder="Select Location" required></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-5">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="user.status" :items="statusOption" item-value="id" item-title="name"
                placeholder="" required></v-select>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      user: {
        id: null,
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        coverages: [],
        status: ''
      },
      statusOption: ['Active', 'Inactive'],
      coverages: [],
      coverageTypes: [],
      roles: [],
      selectedRoleId: null,
      selectedCoverageId: null,
      selectedCoverageTypeId: null,
    };
  },
  watch: {
    coverages(newValue, oldValue) {
      this.user.coverages = []
    }
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchCoverage();
    this.fetchCoverageType();
    // Fetch roles
    try {
      const response = await axios.get('/role'); // Endpoint for fetching roles
      this.roles = response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.messages
        ? error.response.data.messages
        : error.response?.message || 'An error occurred';

      this.showAlert({ message: errorMessage, color: 'error' });
    }

    // Fetch user details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/users/${id}`);
        this.user = response.data;
        this.selectedRoleId = this.user.role_id; // Set selectedRoleId based on user data
        // this.selectedCoverageId = this.user.coverage_id
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const userData = {
          first_name :this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          password: this.user.password,
          password_confirmation: this.user.password,
          role_id: this.selectedRoleId, // Include role_id in submit data
          coverages: this.user.coverages,
          status: this.user.status
        };

        if (this.isAdding) {
          const response = await axios.post('/signup', { user: userData });
          this.showAlert({ message: 'User data successfully saved', color: 'success' });
          if (response.data) {
            this.$router.push({ name: 'user' });
          } else {
            throw new Error(response.message);
          }
        } else {
          const response = await axios.put(`/users/${this.user.id}`, { user: userData });
          this.showAlert({ message: 'User data successfully update', color: 'success' });
          if (response.data) {
            this.$router.push({ name: 'user' });
          } else {
            throw new Error('Failed to update user');
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchCoverage() {
      try {
        const response = await axios.get('/coverage');
        this.coverages = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchCoverageType() {
      try {
        const response = await axios.get('/coverage_type');
        this.coverageTypes = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async findCoverageByType(type) {
      console.log(type)
      try {
        const response = await axios.get(`/coverage?type=${(type).toLowerCase()}`);
        this.coverages = response.data;
      } catch (error) {
        console.log(error)
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An asdfasdf asdf';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
  }
};
</script>
