<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newPeriod') }}</v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <data-table :headers="headers" :items="period" :actions="tableActions" :loading="loading" />
    </v-col>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'Title', key: 'title', align: 'start', clickable: false },
        { title: 'Status', key: 'status', align: 'start', clickable: false },
        { title: '', key: 'actions', align: 'end', sortable: false },
      ],
      period: [],
      tableActions: {
        edit: this.navigateToForm,
        delete: this.deletePeriod,
        view: this.navigateToForm
      },
      loading: false
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'Period-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchPeriod() {
      this.loading = true;
      try {
        const response = await axios.get('/period');
        this.period = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    navigateToForm(action, period = { id: null }) {
      if (action == 'view') {
        this.$router.push({ name: 'period-view', params: { action, id: period.id } });
      } else {
        this.$router.push({ name: 'period-form', params: { action, id: period.id } });
      }
    },
    async deletePeriod(periodToDelete) {
      try {
        const response = await axios.delete(`/period/${periodToDelete.id}`);

        if (response.data) {
          this.period = this.period.filter(u => u.id !== periodToDelete.id);

          this.showAlert({ message: 'Success', color: 'success' });
        } else {
          throw new Error('Failed to delete period');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  mounted() {
    this.fetchPeriod();
  }
};
</script>