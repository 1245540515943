<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Title</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="period.title" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Date</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-date-input v-model="period.date" placeholder="Date" variant="outlined" density="compact"
                :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="period.status" :items="periodStatus"></v-select>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
  components: {
    Breadcrumbs,
    VDateInput
  },
  data() {
    return {
      period: {
        id: null,
        title: '',
        date: null,
        status: null
      },
      periodStatus: ['Open', 'Closed'],
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    // Fetch period details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/period/${id}`);
        const periodData = response.data;

        this.period = {
          id: periodData, id,
          title: periodData.title,
          startDate: new Date(periodData.start_date),
          endDate: new Date(periodData.end_date),
          status: periodData.status
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const periodData = {
          title: this.period.title,
          date: this.period.date,
          status: this.period.status
        };

        if (this.isAdding) {
          const response = await axios.post('/period/', { period: periodData });

          if (response.data) {
            this.$router.push({ name: 'period' });
            this.showAlert({ message: 'Successfully added Period data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/period/${this.period.id}`, { period: periodData });

          if (response.data) {
            this.$router.push({ name: 'period' });
            this.showAlert({ message: 'Successfully added Period data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Period', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    }
  }
};
</script>