<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Transaction Code</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="price.transaction_code" required></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Product</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="selectedProductId" :items="products" item-value="id" item-title="name"
                placeholder="Select Parent Category" required></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Type</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="price.type" :items="types" item-value="id" item-title="name"
                placeholder="Select Parent Category" required></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Price 1</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="price.price_1" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Price 2</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="price.price_2" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Price 3</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="price.price_3" required></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Transaction Code</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="price.name" required></v-text-field>
            </v-col>
          </v-row> -->
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>6
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      price: {
        id: null,
        price_1: null,
        price_2: null,
        price_3: null,
        transaction_code: null
      },
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    // Fetch price details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/price/${id}`);
        this.price = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const priceData = {
          transaction_code: this.price.transaction_code,
          price_1: this.price.price_1,
          price_2: this.price.price_2,
          price_3: this.price.price_3
        };

        if (this.isAdding) {
          const response = await axios.post('/price/', { price: priceData });

          if (response.data) {
            this.$router.push({ name: 'price' });
            this.showAlert({ message: 'Successfully added Price data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/price/${this.price.id}`, { price: priceData });

          if (response.data) {
            this.$router.push({ name: 'price' });
            this.showAlert({ message: 'Successfully added Price data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Price', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    }
  }
};
</script>