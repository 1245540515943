<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newDefiasiLimit') }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="defiasiLimit" :actions="tableActions" :loading="loading" />
      </v-col>
    </v-row>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
          { title: 'Name', key: 'product_name', align: 'start', clickable: true },
          { title: 'SKU', key: 'sku', align: 'start' },
          { title: '', key: 'actions', align: 'end', sortable: false },
        ],
        defiasiLimit: [],
        tableActions: {
          edit: this.navigateToForm,
          delete: this.deleteProductDivision,
          view: this.navigateToForm
        },
        loading: false
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'defiasiLimit-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchDefiasiLimit() {
        this.loading = true;
        try {
          const response = await axios.get('/defiation_limit');
          this.defiasiLimit = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, defiasiLimit = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'defiasiLimit-view', params: { action, id: defiasiLimit.id } });
        } else {
          this.$router.push({ name: 'defiasiLimit-form', params: { action, id: defiasiLimit.id } });
        }
      },
      async deleteProductDivision(defiasiLimitToDelete) {
        try {
          const response = await axios.delete(`/defiation_limit/${defiasiLimitToDelete.id}`);
  
          if (response.data) {
            this.defiasiLimit = this.defiasiLimit.filter(u => u.id !== defiasiLimitToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete defiasiLimit');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    mounted() {
      this.fetchDefiasiLimit();
    }
  };
  </script>